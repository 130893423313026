<template>
  <div>
    <b-card>
      <b-table striped :fields="fields" :items="items">
        <template #cell(opc)="data">
          <b-button size="sm" variant="danger" @click="excluir(data.item)"
            ><i class="fa fa-trash"></i> Excluir</b-button
          >
        </template>
        <template #cell(vl_unitario)="data">
          <b-form-input
            v-model="data.item.vl_unitario"
            type="text"
            v-money="moneyMask"
            class="text-center"
          />
        </template>
        <template #cell(qtd)="data">
          <b-form-input
            v-model="data.item.qtd"
            type="number"
            class="text-center"
          />
        </template>
        <template #cell(Total)="data">
          {{ data.item.Total | currency }}
        </template>
        <template #cell(product)="data">
          <b-btn style="min-width:150px" variant="outline-primary" block class="text-left" large>{{ data.item.product }}</b-btn>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      fields: [
        { key: "count", label: "#", sortable: true, class: "text-center" },
        { key: "product", label: "Produto" },
        { key: "vl_unitario", label: "Valor Un.", class: "text-center" },
        { key: "qtd", label: "Qtd", class: "text-center" },
        { key: "Total", label: "Total", class: "text-center" },
        { key: "opc", label: "", class: "text-center" },
      ],
      items: [
        {
          count: 1,
          product: "Produto 1",
          vl_unitario: 0.0,
          qtd: 1,
          Total: 0.0,
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
