<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="Empresa">
          <EmpresaCombo
            v-model="notaForm.cod_empresa"
            :disabled="notaForm.id > 0"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6">
        <b-form-group label="Data">
          <b-input type="date" v-model="notaForm.data" disabled />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6 d-flex">
        <b-form-group label="Número" inline>
          <b-input type="text" v-model="notaForm.numero" disabled />
        </b-form-group>
        <b-form-group label="Série" inline class="ml-2">
          <b-input type="text" v-model="notaForm.numero" disabled />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6">
        <b-form-group label="Chave">
          <b-input type="text" v-model="notaForm.chave" disabled />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-tabs content-class="mt-">
          <b-tab>
            <template v-slot:title>
              <b-icon icon="person" />
              Cliente
            </template>
            <b-card>
              <b-radio-group v-model="notaForm.clienteInformado" class="d-flex">
                <b-form-radio value="N" name="cliente" class="mr-2">
                  Não Informado
                </b-form-radio>
                <b-form-radio value="S" name="cliente" class="mr-2">
                  Informado
                </b-form-radio>
              </b-radio-group>
              <b-card v-if="notaForm.clienteInformado == 'S'">
                <ClienteForm
                  v-model="notaForm.cliente"
                  :tipo_entrega="{ titulo: 'ENTREGA' }"
                />
              </b-card>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="list" />
              Produtos
            </template>
            <FiscalFormProdutos></FiscalFormProdutos>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="cash" />
              Pagamento
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="calculator" />
              Totais
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="arrow-repeat" />
              Log
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="code" />
              XML
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <b-icon icon="printer" />
              Danfe
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="mt-2 pt-2 w-100">
      <hr />
      <div class="d-flex justify-content-between">
        <div>
          <b-button variant="danger" class="mr-2" disabled>
            <i class="fa fa-times"></i> Cancelar
          </b-button>
        </div>
        <div>
          <b-button variant="info" class="mr-2" disabled>
            <i class="fa fa-paper"></i> Emitir
          </b-button>

          <b-button variant="success" class="mr-2">
            <i class="fa fa-save"></i> Salvar
          </b-button>
          <b-button variant="outline-secondary">
            <i class="fa fa-ban"></i> Cancelar
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpresaCombo from "@/components/empresa/EmpresaCombo.vue";
import moment from "moment"; 
import ClienteForm from "../../components/clientes/ClienteForm.vue";
import FiscalFormProdutos from "./FiscalFormProdutos.vue";
export default {
  components: {
    EmpresaCombo,
    ClienteForm,
    FiscalFormProdutos
  },
  props: {
    empresa: {
      type: Object,
    },
    notaId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
  data() {
    return {
      notaForm: {
        id: 0,
        cod_empresa: 0,
        data: moment().format("YYYY-MM-DD"),
        numero: "",
        serie: "",
        chave: "",
        vl_total: 0,
        clienteInformado: "S",
        cliente: {
          nome: "",
          cpf: "",
          endereco: "",
          cidade: "",
          uf: "",
          cep: "",
          fone: "",
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
