<template>
  <div>
    <div class="p-4">
      <b-card class="round">
        <b-card-title class="d-flex justify-content-between">
          <div>
            <h3>Notas Emitidas</h3>
            <div class="d-flex">
              <b-select
                :options="['Todas', 'Concluido', 'Erro']"
                v-model="filtros.status"
                class="mr-1"
              ></b-select>
              <b-select
                :options="['Todas', 'Produção', 'Homologação']"
                v-model="filtros.ambiente"
              ></b-select>
            </div>
          </div>
          <div style="min-width: 200px">
            <b-btn
              variant="success"
              block
              class="mb-2"
              @click="novaNota"
              >Nova Nota</b-btn
            >
            <v-date-picker
              v-model="date"
              is-range
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                    :value="`${inputValue.start} - ${inputValue.end}`"
                    v-on="inputEvents.start"
                    class="form-control"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </b-card-title>
        <b-card-text>
          <b-skeleton-table
            :rows="5"
            :columns="5"
            v-if="loading"
          />
          <b-table
            v-if="!loading"
            :items="notas"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            @filtered="onFiltered"
            hover
            small
            style="font-size: 12px"
            striped
          >
            <template #cell(numero)="data">
              {{ data.item.numero }}
            </template>
            <template #cell(pedido)="data">
              <div v-if="data.item.cod_pedido">
                <small
                  ><b>
                    Senha: {{ data.item.senha }}
                    <br />
                    <small> #{{ data.item.cod_pedido }} </small>
                  </b></small
                >
              </div>
              <div v-if="data.item.mesa_controle_id">
                <small
                  ><b>Conta #{{ data.item.mesa_controle_id }}</b></small
                >
              </div>
            </template>
            <template #cell(data)="data">
              {{ data.item.data_envio | moment("DD/MM/YYYY") }}
            </template>

            <template v-slot:cell(valor_total)="data">
              {{ data.item.valor_total | currency }}
            </template>
            <template v-slot:cell(nome_empresa)="data">
              {{ data.item.nome_empresa }}
            </template>
            <template v-slot:cell(nome_cliente)="data">
              <div v-if="data.item.cpf_cliente">
                {{ data.item.nome_cliente }}<br />
                <small>CPF/CNPJ: {{ data.item.cpf_cliente }}</small>
              </div>
            </template>
            <template v-slot:cell(acao)="data">
              <div
                style="min-width: 160px"
                class="text-center"
              >
                <b-button
                  size="sm"
                  @click="verNota(data.item)"
                  variant="primary"
                >
                  <i class="fa fa-eye"></i>
                </b-button>
                <b-btn
                  class="ml-2"
                  size="sm"
                  @click="openDanfe(data.item)"
                  variant="success"
                >
                  <i class="fa fa-print"></i>
                </b-btn>

                <b-btn
                  class="ml-2"
                  size="sm"
                  variant="info"
                  @click="downloadXML(data.item)"
                >
                  <i class="fa fa-code"></i>
                </b-btn>
              </div>
            </template>
            <template #cell(integracao)="data">
              <div
                v-if="data.item.integracao == 'LOCAL-HOMOLOGACAO'"
                class="text-center text-white bg-info"
              >
                Homologação
              </div>
              <div
                v-else
                class="text-center text-white bg-success"
              >
                Produção
              </div>
            </template>
            <template #cell(status)="data">
              <div
                v-if="data.item.status == 'Concluido'"
                class="text-center"
              >
                <b-badge variant="success">Concluído</b-badge>
              </div>
              <div
                v-else
                class="text-center"
              >
                <b-badge variant="warning">{{ data.item.status }}</b-badge>
              </div>
            </template>
          </b-table>
          <div class="row">
            <div class="col-12 col-md-8">
              <b-pagination
                v-model="currentPage"
                :total-rows="notas.length"
                :per-page="perPage"
              ></b-pagination>
            </div>
            <div class="col-12 col-md-4">
              <b-card>
                <div class="d-flex justify-content-between">
                  <div><b>Total</b></div>
                  <div>
                    {{ totalNotas | currency }}
                  </div>
                </div>
                <div>
                  <hr />
                  <div
                    v-for="(valor, situacao) in totalNotasPorSituacao"
                    :key="situacao"
                    style="font-size: 12px"
                  >
                    <div class="d-flex justify-content-between">
                      <div>{{ situacao }}</div>
                      <div>{{ valor | currency }}</div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <b-modal
        id="modal-fiscal"
        title="NFCe"
        hide-footer
        v-if="CupomOpen"
        size="xl"
        @hide="CupomOpen = null"
      >
        <CupomFiscal
          :PedidoID="CupomOpen.cod_pedido"
          :CodEmpresa="CupomOpen.cod_empresa"
          :MesaContaID="CupomOpen.mesa_controle_id"
          :notaID="CupomOpen.id"
        />
      </b-modal>
      <b-modal
        id="nota-form"
        size="lg"
        title="Nova nota"
        hide-footer
      >
        <FiscalForm
          :empresa="empresa"
          @close="showNotaForm = false"
          @save="buscarNotas"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CupomFiscal from "../../components/Caixas/CupomFiscal.vue";
import FiscalLib from "../../libs/FiscalLib";
import FiscalForm from "./FiscalForm";
import moment from "moment";
import axios from "../../utils/axios";

export default {
  components: {
    CupomFiscal,
    FiscalForm,
  },
  props: {
    empresa: {
      type: Object,
    },
  },
  mounted() {
    this.buscarNotas();
  },
  data() {
    return {
      loading: true,
      date: {
        start: new Date(),
        end: new Date(),
      },
      filtros: {
        status: "Todas",
        ambiente: "Todas",
      },
      notas: [],
      currentPage: 1,
      sortBy: "data",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      totalPages: 0,
      perPage: 10,
      CupomOpen: null,
      showNotaForm: false,
      fields: [
        {
          key: "id",
          label: "Id. Nota",
          sortable: true,
          class: "text-center",
        },
        {
          key: "numero",
          label: "Numero",
          sortable: true,
          class: "text-center",
        },
        { key: "serie", label: "Série", sortable: true, class: "text-center" },
        { key: "integracao", label: "Ambiente", sortable: true },
        { key: "pedido", label: "Pedido/Conta", sortable: true },
        { key: "data", label: "Data", sortable: true, class: "text-center" },
        {
          key: "status",
          label: "Situação",
          sortable: true,
          class: "text-center",
        },
        { key: "nome_empresa", label: "Empresa", sortable: true },
        { key: "nome_cliente", label: "Cliente", sortable: true },
        {
          key: "valor_total",
          label: "Valor",
          sortable: true,
          class: "text-right",
        },
        { key: "acao", label: "Ação", sortable: false, class: "text-center" },
      ],
    };
  },
  watch: {
    empresa: {
      handler: function () {
        this.buscarNotas();
      },
      deep: true,
    },
    date: {
      handler: function () {
        this.buscarNotas();
      },
      deep: true,
    },
    filtros: {
      handler: function () {
        this.buscarNotas();
      },
      deep: true,
    },
  },
  computed: {
    totalNotas: function () {
      return this.notas.reduce((acc, nota) => {
        return acc + nota.valor_total;
      }, 0);
    },
    totalNotasPorSituacao: function () {
      return this.notas.reduce((acc, nota) => {
        if (!acc[nota.status]) {
          acc[nota.status] = 0;
        }
        acc[nota.status] += nota.valor_total;
        return acc;
      }, {});
    },
  },
  methods: {

    openDanfe(item) {
      if (!item || item.status !== "Concluido") {
        this.$swal("Nota não concluida!", "", "error");
        return;
      }
      // window.open(this.getUrlDanfe());
      //open file in new tab from this.getUrlDanfe()
      window.open(this.getUrlDanfe(item), "_blank");
    },
    getUrlDanfe(item) {
      //this.notaInfo.danfe is base64 for file, open it
      // return `data:application/pdf;base64,${this.notaInfo.danfe}`;
      return `${axios.defaults.baseURL.substring(0, axios.defaults.baseURL.length - 1)}${
        item.danfe
      }?token=${axios.defaults.headers["common"]["Authorization"].replace(
        "Bearer ",
        ""
      )}`;
    },

    async downloadXML(item){
      await FiscalLib.baixarXMLFile(item.id)
    },
    novaNota() {
      this.showNotaForm = true;
      this.$nextTick(() => {
        this.$bvModal.show("nota-form");
      });
    },
    async verNota(nf) {
      this.CupomOpen = nf;
      await this.$nextTick();
      await this.$forceUpdate();
      this.$bvModal.show("modal-fiscal");
    },
    onFiltered(fil) {
      console.log(fil);
    },
    async buscarNotas() {
      this.loading = true;
      try {
        this.notas = [];
        this.currentPage = 1;
        this.totalPages = 1;
        let result = await FiscalLib.buscarNotaEmitidas({
          empresa: this.empresa.cod_empresa,
          data_inicio: moment(this.date.start).format("YYYY-MM-DD"),
          data_fim: moment(this.date.end).format("YYYY-MM-DD"),
          ...this.filtros,
        });
        console.log("resuilt", result);
        if (result && result.success) {
          this.notas = result.data;
          this.currentPage = 1;
          this.totalPages = Math.ceil(this.notas.length / this.perPage);
        }
      } catch (error) {
        console.log("error", error);
      }
      // this.notas = data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
